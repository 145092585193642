import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        1993年滋賀県生まれ。<br />
        京都精華大学デザイン学部を卒業後、映像制作会社、フリーランスを経てモンタージュに入社。<br />
        企業PV・展示映像・LIVE映像・SNSアニメ動画などの制作に携わる。<br />
        作家としてイラストレーション、アニメーション、陶芸などの作品も制作している。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
